/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fcbf1e ; 
  min-height: 200px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FBC02D; 
}